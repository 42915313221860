import GATSBY_COMPILED_MDX from "/opt/build/repo/content/works/brain-games/index.mdx";
import React from 'react';
import {graphql} from 'gatsby';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ImageGallery from 'components/image-gallery';
import Meta from 'components/meta';
import RedirectToParent from 'components/redirect-to-parent';
import Wrapper from 'components/wrapper';
function MdxWorkGallery({location, pageContext, data: {mdx, mediaGallery}}) {
  const {frontmatter: fm} = mdx ?? ({});
  const {title, label, projectData} = pageContext ?? ({});
  if (!mediaGallery || !projectData) return React.createElement(RedirectToParent, {
    location: location
  });
  const galleryData = Object.assign({}, mediaGallery, projectData, {
    pages: projectData.pages?.filter(item => {
      return item.route != location?.pathname.replace(/\/$/, '');
    })
  });
  const galleryTitle = title ?? fm?.title ?? projectData.title;
  const description = galleryTitle + (galleryData.year ? ` (${galleryData.year})` : '') + (label ? ` - ${label}` : '');
  return React.createElement(Wrapper, {
    location: location,
    variant: "grid",
    wrapperClass: "d-flex flex-column w-100 mx-auto",
    bodyClass: "bg-body-darker"
  }, React.createElement(Meta, {
    title: galleryTitle,
    author: fm?.author,
    description: description
  }), React.createElement(Row, {
    className: "text-center"
  }, React.createElement(Col, null, galleryTitle && React.createElement("div", {
    className: "media-title fs-4 pt-3 pb-1"
  }, galleryTitle, " ", projectData.year && React.createElement("span", null, ` (${projectData.year})`)), React.createElement(ImageGallery, {
    galleryData: galleryData
  }))), galleryData.note && React.createElement(Row, {
    className: "text-center"
  }, React.createElement(Col, null, React.createElement("div", {
    className: "media-note fs-6 pt-2"
  }, galleryData.note))), galleryData.credit && React.createElement(Row, {
    className: "text-center"
  }, React.createElement(Col, null, React.createElement("div", {
    className: "media-credit pt-2"
  }, galleryData.credit.label ?? 'Credit', ":", ' ', galleryData.credit.name ?? galleryData.credit))), React.createElement(Row, {
    className: "py-3 g-1 justify-content-center text-nowrap"
  }, galleryData?.pages?.map((item, index) => React.createElement(Col, {
    key: index,
    className: "flex-grow-0"
  }, React.createElement(Button, {
    className: "fs-6",
    variant: "primary",
    size: "sm",
    href: item.route
  }, item.label ?? `Link #${index}`))), React.createElement(Col, {
    className: "flex-grow-0"
  }, React.createElement(Button, {
    className: "fs-6",
    variant: "primary",
    size: "sm",
    href: "/catalog"
  }, "Project Catalog"))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(MdxWorkGallery, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($id: String, $key: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        author
        title
      }
    }
    mediaGallery: galleryDataYaml(key: { eq: $key }) {
      aspect
      credit {
        label
        name
      }
      key
      note
      slides
      title
    }
  }
`;
